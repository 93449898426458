import { render, staticRenderFns } from "./ShowDoorly.vue?vue&type=template&id=2f0fa27c&scoped=true&"
import script from "./ShowDoorly.vue?vue&type=script&lang=js&"
export * from "./ShowDoorly.vue?vue&type=script&lang=js&"
import style0 from "./ShowDoorly.vue?vue&type=style&index=0&id=2f0fa27c&lang=scss&scoped=true&"
import style1 from "./ShowDoorly.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0fa27c",
  null
  
)

export default component.exports